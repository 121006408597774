import React, { useEffect } from 'react'
import axios from 'axios'

import useWidth from 'src/hooks/window/useWidth'
import useDomReady from 'src/hooks/window/useDomReady'

import * as S from './styles'
import * as T from './types'
import * as URLS from 'src/config/api/Urls'
import * as oldColors from 'src/styles/colors'
import * as newColors from 'src/styles/newColors'

import { Button } from '@interco/inter-ui/components/Button'
import { COMMON_MASKS, Input } from '@interco/inter-ui/components/Input'
import { useForm, UseFormMethods } from 'react-hook-form'
import { Checkbox } from '@interco/inter-ui/components/Checkbox'
import { initTheme, Theme } from '@interco/inter-ui'
import { MultiValue, Select, SelectOption, SingleValue } from '@interco/inter-ui/components/Select'

import SuccessIcon from '@interco/icons/core/status/ic_check_circle_fill'
import ErrorIcon from '@interco/icons/core/status/ic_close_circle_fill'
import CloseIcon from '@interco/icons/core/action-navigation/ic_close'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

const FiquePorDentro = () => {
  const domReady = useDomReady()
  const [ success, setSuccess ] = React.useState<boolean>(false)
  const [ error, setError ] = React.useState<boolean>(false)
  const [ sendDatalayerEvent ] = useDataLayer()

  useEffect(() => {
    initTheme(Theme.PF)
  })

  function resetForm () {
    setSuccess(false)
    setError(false)
  }

  return (
    <S.ContentWrapper className='content_wrapper'>
      <div className='container row'>
        { !success && !error && domReady && (
          <FiquePorDentro.Form
            setError={setError}
            setSuccess={setSuccess}
          />)
        }
        { success && domReady && (
          <FiquePorDentro.SuccessMessageScreen
            onClick={() => {
              resetForm()
              sendDatalayerEvent({
                section: 'dobra_02',
                section_name: 'Fique por dentro',
                element_action: 'click button',
                element_name: 'X',
              })
            }}
          />)
        }
        { error && domReady && (
          <FiquePorDentro.ErrorMessageScreen
            onClick={() => {
              resetForm()
              sendDatalayerEvent({
                section: 'dobra_02',
                section_name: 'Fique por dentro',
                element_action: 'click button',
                element_name: 'X',
              })
            }}
          />)
        }
      </div>
    </S.ContentWrapper>
  )
}

FiquePorDentro.SuccessMessageScreen = ({ onClick }: T.IMessagesScreens) => {
  return (
    <div className='content_wrapper__form_response'>
      <CloseIcon
        height={20}
        width={20}
        color={newColors.orange.extra}
        className='content_wrapper__form_response__close'
        onClick={onClick}
      />
      <SuccessIcon
        height={50}
        width={50}
        color={oldColors.green[400]}
        className='content_wrapper__form_response__icon'
      />
      <h3 className='content_wrapper__form_response__title'>
        Obrigado!
      </h3>
      <p className='content_wrapper__form_response__description'>
        Seus dados foram recebidos com sucesso!
      </p>
    </div>
  )
}

FiquePorDentro.ErrorMessageScreen = ({ onClick }: T.IMessagesScreens) => {
  return (
    <div className='content_wrapper__form_response'>
      <CloseIcon
        height={20}
        width={20}
        color={newColors.orange.extra}
        className='content_wrapper__form_response__close'
        onClick={onClick}
      />
      <ErrorIcon
        height={50}
        width={50}
        color={oldColors.red[500]}
        className='content_wrapper__form_response__icon'
      />
      <h3 className='content_wrapper__form_response__title'>
        Algo deu errado
      </h3>
      <p className='content_wrapper__form_response__description'>
        Não conseguimos enviar seus dados. Por favor, tente novamente.
      </p>
    </div>
  )
}

FiquePorDentro.Form = ({ setSuccess, setError }: T.IFormProps) => {
  const domReady = useDomReady()
  const width = useWidth()
  const [ sendDatalayerEvent ] = useDataLayer()
  const formState: UseFormMethods<T.FormDataProps> = useForm<T.FormDataProps>()
  const [ loading, setLoading ] = React.useState<boolean>(false)
  const [ perfil, setPerfil ] = React.useState<SingleValue<SelectOption>>({ label: '', value: '' })
  const [ checkboxStates, setCheckboxStates ] = React.useState({
    sendNewsletter: false,
    sendOtherContents: false,
    acceptTerm: false,
  })
  function onSubmit (value: T.FormDataProps) {
    setLoading(true)

    const formData = [
      {
        nome: value.name,
        email: value.email,
        CpfCnpj: value.cpf.replaceAll(/[.-]/g, ''),
        aceite: true,
        campanha: 'Newsroom Institucional',
        Produto: perfil?.label,
        Conteudo01: checkboxStates.sendNewsletter ? 'Sim' : 'Nao',
        Conteudo02: checkboxStates.sendOtherContents ? 'Sim' : 'Nao',
        Conteudo03: 'PT',
      },
    ]

    axios.post(`${URLS.CONTACT_FORM_POST_V5}/lp-mktcloud-inter`, formData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(() => setSuccess(true))
      .catch(() => setError(true))

    setLoading(false)
  }

  const perfilOptions: SelectOption[] = [
    { label: 'Acionista', value: 'acionista' },
    { label: 'Jornalista', value: 'jornalista' },
    { label: 'Membro de órgão regulador', value: 'membro-orgao-regulador' },
    { label: 'Prestador de serviços do Inter', value: 'prestador-servicos-inter' },
    { label: 'Analista de investimentos', value: 'analista-investimentos' },
    { label: 'Profissional de relações com investidores', value: 'profissional-relacoes-investidores' },
    { label: 'Estudante', value: 'estudante' },
    { label: 'Colaborador do Inter', value: 'colaborador-inter' },
    { label: 'Cliente do Inter', value: 'cliente-inter' },
    { label: 'Outro', value: 'outro' },
  ]

  const image = domReady && (
    <picture className='content_wrapper__form__image'>
      <source
        srcSet='https://central-imagens.bancointer.com.br/images-without-small-versions/newsroom-768/image.webp'
        media='(min-width: 768px)'
      />
      <img
        src='https://central-imagens.bancointer.com.br/images-without-small-versions/newsroom-360/image.webp'
        alt=''
        className='content_wrapper__form__image__source'
        loading='lazy'
      />
    </picture>
  )

  const fields = domReady && (
    <form
      id='fiquePorDentroForm'
      onSubmit={formState.handleSubmit(onSubmit)}
      className='content_wrapper__form__fields'
    >
      <Input
        className='content_wrapper__form__fields__input'
        id='name'
        name='name'
        label='Nome'
        placeholder='Digite seu nome'
        infoText={formState.errors.name?.message}
        type='text'
        error={!!formState.errors.name}
        ref={formState.register({
          required: 'Digite seu nome completo',
          validate: {
            noNumbers: (value: string) => !/\d/.test(value) || 'Digite um nome válido',
            isComplete: (value: string) => value.split(' ').length > 1 || 'Digite seu nome completo',
          },
        })}
      />

      <Input
        className='content_wrapper__form__fields__input'
        id='email'
        name='email'
        label='E-mail'
        placeholder='Digite seu e-mail'
        infoText={formState.errors.email?.message}
        type='email'
        error={!!formState.errors.email}
        ref={formState.register({
          required: 'Digite um e-mail válido',
          pattern: {
            value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            message: 'Digite um e-mail válido',
          },
        })}
      />

      <Input
        className='content_wrapper__form__fields__input'
        id='cpf'
        name='cpf'
        label='CPF'
        placeholder='000.000.000-00'
        infoText={formState.errors.cpf?.message}
        error={!!formState.errors.cpf}
        mask={COMMON_MASKS.CPF}
        ref={formState.register({
          required: 'Digite um CPF válido',
          validate: (value: string) => value.length === 14 || 'Digite um CPF válido',
        }) }
      />

      <label
        className='content_wrapper__form__fields__labels'
        htmlFor='perfilSelect'
      >
        Perfil
      </label>

      <Select
        id='perfilSelect'
        name='perfil'
        className='content_wrapper__form__fields__input'
        options={perfilOptions}
        placeholder='Eu sou...'
        infoText={formState.errors.perfil?.message}
        error={!!formState.errors.perfil}
        required
        onChange={(value: MultiValue<SelectOption> | SingleValue<SelectOption>) => {
          setPerfil(value as SingleValue<SelectOption>)
        }}
      />

      <label
        className='content_wrapper__form__fields__labels content_wrapper__form__fields__labels--content'
      >
        Qual tipo de conteúdo você quer receber?
      </label>

      <div className='content_wrapper__form__fields__content'>
        <div className='content_wrapper__form__fields__accept'>
          <Checkbox
            className='content_wrapper__form__fields__accept__checkbox'
            id='checkboxNewsletter'
            name='sendNewsletter'
            checked={checkboxStates.sendNewsletter}
            onChange={() => setCheckboxStates({ ...checkboxStates, sendNewsletter: !checkboxStates.sendNewsletter })}
          />
          <label
            htmlFor='checkboxNewsletter'
            className='content_wrapper__form__fields__accept__text'
          >
            Newsletter mensal <span className='content_wrapper__form__fields__accept__text--light'>(conteúdos sobre educação financeira, inovação e tecnologia)</span>
          </label>

        </div>

        <div className='content_wrapper__form__fields__accept'>
          <Checkbox
            className='content_wrapper__form__fields__accept__checkbox'
            id='checkboxOtherContents'
            name='sendOtherContents'
            checked={checkboxStates.sendOtherContents}
            onChange={() => setCheckboxStates({ ...checkboxStates, sendOtherContents: !checkboxStates.sendOtherContents })}
          />
          <label
            htmlFor='checkboxOtherContents'
            className='content_wrapper__form__fields__accept__text'
          >
            E-mails institucionais sobre o Inter <span className='content_wrapper__form__fields__accept__text--light'>(parcerias, conquistas, resultados e outras novidades)</span>
          </label>
        </div>
      </div>

      <div className='content_wrapper__form__fields__accept content_wrapper__form__fields__accept--terms'>
        <Checkbox
          className='content_wrapper__form__fields__accept__checkbox'
          id='checkboxAcceptTerm'
          checked={checkboxStates.acceptTerm}
          onChange={() => setCheckboxStates({ ...checkboxStates, acceptTerm: !checkboxStates.acceptTerm })}
        />
        <label htmlFor='checkboxAcceptTerm' className='content_wrapper__form__fields__accept__text--light'>Autorizo o Inter a tratar meus dados pessoais para envio de comunicações sobre seus produtos e serviços e também estou de acordo com a Política de Privacidade</label>
      </div>

      <Button
        as='button'
        form='fiquePorDentroForm'
        type='submit'
        isLoading={loading}
        disabled={!(checkboxStates.acceptTerm && (checkboxStates.sendNewsletter || checkboxStates.sendOtherContents))}
        className='content_wrapper__form__fields__submit'
        onClick={() => sendDatalayerEvent({
          section: 'dobra_02',
          section_name: 'Fique por dentro',
          element_action: 'submit',
          element_name: 'Cadastrar',
        })}
      >
        Cadastrar
      </Button>
    </form>
  )

  return (
    <article className='col-12 content_wrapper__form'>
      {width < 768 && image}
      <h2 className='content_wrapper__form__title'>
        Fique por dentro
      </h2>
      <p className='content_wrapper__form__description'>
        Escolha os assuntos do seu interesse e receba notícias sob medida pra você.
      </p>
      <div className='d-flex flex-direction-row'>
        {fields}
        { width < 1440 && width >= 768 && image}
      </div>
    </article>
  )
}

export default FiquePorDentro
