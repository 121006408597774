import React from 'react'
import * as S from './styles'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import OrangeCard from './components/OrangeCard/_index'
import * as newColors from 'src/styles/newColors'

import { cardsInfos, CardsInfosProps } from './_cardsInfos'

const CarouselSec = () => {
  return (
    <S.ContentWrapper>
      <DefaultCarousel
        customDotColor={newColors.primary[500]}
        sm={{ items: 1, partialVisibilityGutter: 50 }}
        md={{ items: 2, partialVisibilityGutter: 20 }}
        lg={{ items: 2, partialVisibilityGutter: 120 }}
        xl={{ items: 2, partialVisibilityGutter: 160 }}
        containerClass='content_wrapper__carousel'
      >
        { cardsInfos.map((card: CardsInfosProps, index: number) => (
          <OrangeCard
            title={card.title}
            description={card.description}
            downloadOrEmail={card.downloadOrEmail}
            icon={card.icon}
            key={`card${index}`}
            redirectUrl={card.redirectUrl}
            dataLayerParams={{
              section: 'dobra_03',
              element_action: 'click button',
              element_name: card.downloadOrEmail,
              section_name: 'Reports and contacts carousel',
              redirect_url: card.redirectUrl ?? '',
            }}
          />
        ))}
      </DefaultCarousel>
    </S.ContentWrapper>
  )
}

export default CarouselSec
